var oneTrustScriptElement = document.createElement('script');

window.analytics = {
  platform: SPRD.PLATFORM.toUpperCase(),
};

if (SPRD.PLATFORM.toUpperCase() === 'NA') {
  oneTrustScriptElement.src =
    'https://www.spreadshirt.com/resources/cookie/script.js';
} else {
  oneTrustScriptElement.src =
    'https://www.spreadshirt.net/resources/cookie/script.js';
}

oneTrustScriptElement.onload = function () {
  var analyticsScriptElement = document.createElement('script');

  if (
    SPRD.ENVIRONMENT.toUpperCase() !== 'OPS' ||
    window.location.hostname.startsWith('rentrap')
  ) {
    analyticsScriptElement.src =
      '//adtm.spreadshirts.net/launch/3a098ad49155/afaf194453fd/launch-7e0a1ffca031-development.min.js';
  } else {
    analyticsScriptElement.src =
      '//adtm.spreadshirts.net/launch/3a098ad49155/afaf194453fd/launch-cb46c4852cd7.min.js';
  }

  document.getElementsByTagName('body')[0].appendChild(analyticsScriptElement);
};

document.getElementsByTagName('body')[0].appendChild(oneTrustScriptElement);
